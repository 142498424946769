import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  DELPRODUCT,
  getProducts,
  ProductEnableDisable,
} from "../../../redux/actions/productActions";
import { useDispatch, useSelector } from "react-redux";
import { RESET_ERROR } from "../../../redux/constants/userConstants";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import SearchHeader from "../../common/header_components/searchHeader";
import ToggleSwitch from "../../common/ToggleSwitch";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const Products = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { productlist, productdeleted, productenabled } = useSelector(
    (state) => state.product
  );
  const customToast = "delPro";

  const [filterText, setFilterText] = useState("");
  const [datas, setDatas] = useState([]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  useEffect(() => {
    if (productlist) {
      setDatas(productlist);
    }
  }, [productlist]);

  const handleDelete = (id) => {
    if (window.confirm("Are you sure you wish to delete this item?")) {
      dispatch(DELPRODUCT(id));
    }
  };

  const navigateEditPage = (id) => {
    navigate(`/products/EditProduct/${id}`);
  };

  useEffect(() => {
    if (productdeleted) {
      toast.success(" Product deleted successfully", { toastId: customToast });
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [productdeleted]);

  useEffect(() => {
    console.log("inside filter");
    let datas = productlist?.filter(
      (ord) =>
        ord?.name?.toLowerCase().includes(filterText?.toLowerCase()) ||
        ord?.description?.toLowerCase().includes(filterText?.toLowerCase()) ||
        ord?.category?.name
          ?.toLowerCase()
          .includes(filterText?.toLowerCase()) ||
        ord?.subCategory?.name
          ?.toLowerCase()
          .includes(filterText?.toLowerCase()) ||
        ord?.units
          ?.map((un) => un?.name?.toLowerCase())
          .join(",")
          .includes(filterText?.toLowerCase())
    );
    setDatas(datas);
  }, [filterText]);

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };

    return (
      <div style={{ display: "flex", justifyContent: "flex-end", flex: 1 }}>
        <SearchHeader
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText]);

  const columnStyles = {
    width: "auto", // Ensure column width does not stretch the switch
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const enabledTooltip = (
    <Tooltip id="enabled-tooltip" className="custom-tooltip">
      Enabled
    </Tooltip>
  );
  const disabledTooltip = (
    <Tooltip id="disabled-tooltip" className="custom-tooltip">
      Disabled
    </Tooltip>
  );

  let columns = [
    {
      name: <b>{Capitalize("S.No")}</b>,
      header: <b>{Capitalize("S.No")}</b>,
      cell: (row, index) => index + 1,
    },
    {
      name: <b>{Capitalize("Image")}</b>,
      header: <b>{Capitalize("Image")}</b>,
      cell: (row) => (
        <>
          <span>
            <img
              style={{ width: 40, height: 40 }}
              src={`${row?.imageBasePath}${row?.image[0]}`}
            ></img>
          </span>
        </>
      ),
    },
    {
      name: <b>{Capitalize("Name")}</b>,
      header: <b>{Capitalize("Name")}</b>,
      selector: (row) => row?.name,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("description")}</b>,
      header: <b>{Capitalize("description")}</b>,
      selector: (row) => (row?.description === "null" ? "" : row?.description),
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("category")}</b>,
      header: <b>{Capitalize("category")}</b>,
      selector: (row) => row?.category?.name,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("subcategory")}</b>,
      header: <b>{Capitalize("subcategory")}</b>,
      selector: (row) => row?.subCategory?.name,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("unit")}</b>,
      header: <b>{Capitalize("unit")}</b>,
      selector: (row) =>
        row?.units &&
        row?.units?.map((res) => (
          <div key={res?.name}>
            <span>{res?.name}</span>
          </div>
        )),
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Status")}</b>,
      header: <b>{Capitalize("Status")}</b>,
      cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={row?.status === "active" ? enabledTooltip : disabledTooltip}
        >
          <div style={columnStyles}>
            <ToggleSwitch
              isActive={row?.status === "active"}
              onToggle={async () => {
                const newStatus =
                  row.status === "active" ? "inactive" : "active";
                const formdata = { id: row._id, status: newStatus };
                try {
                  // Make the API call and wait for it to complete
                  await dispatch(ProductEnableDisable(formdata));

                  // Update the state only after the API call succeeds
                  setDatas((prevData) =>
                    prevData.map((product) =>
                      product._id === row._id
                        ? { ...product, status: newStatus }
                        : product
                    )
                  );
                } catch (error) {
                  console.error("Failed to update status:", error);
                  // Optionally handle the error, such as showing an error message
                }
              }}
            />
          </div>
        </OverlayTrigger>
      ),
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>Actions</b>,
      id: "delete",
      accessor: () => "delete",
      cell: (row) => (
        <>
          <span onClick={() => handleDelete(row._id)}>
            <i
              className="fa fa-trash"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "#e4566e",
                cursor: "pointer",
              }}
            ></i>
          </span>
          <span onClick={() => navigateEditPage(row._id)}>
            <i
              className="fa fa-pencil"
              style={{
                width: 35,
                fontSize: 20,
                padding: 11,
                color: "rgb(40, 167, 69)",
                cursor: "pointer",
              }}
            ></i>
          </span>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (productenabled) {
      toast.success("Product status updated successfully");
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [productenabled]);

  useEffect(() => {
    dispatch(getProducts());
  }, []);

  if (!productlist) {
    return <div>Loading...</div>;
  }

  return (
    <Fragment>
      <Breadcrumb title="Products List" parent="Products" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid="true">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Product Details</h5>
              </CardHeader>
              <CardBody>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  <Fragment>
                    {productlist && (
                      <DataTable
                        pagination
                        title=""
                        columns={columns}
                        data={datas}
                        fixedHeader
                        subHeader
                        subHeaderComponent={subHeaderComponentMemo}
                      />
                    )}
                  </Fragment>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* <!-- Container-fluid Ends--> */}
    </Fragment>
  );
};

export default Products;
