import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import CustomTextArea from "../common/CustomTextArea";
import ImageInput from "../common/ImageInput";
import CustomInput from "../common/CustomInput";
import ImagesInput from "../common/ImagesInput";
import axios from "axios";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";
const AddBlog = ({ openModal, onCloseModal, id }) => {
  const [loading, setLoading] = useState(false);
	const [image, setImage] = useState(null)

  const dispatch = useDispatch();
  const schema = yup
    .object({
      title: yup.string().max(40).required("Title field is Required"),
      description: yup.string().required("Description field is Required"),
      image: yup.mixed()
          .test(
            "required",
            "Image resolution should be 256px * 256px",
            (file) => {
              // return file && file.size <-- u can use this if you don't want to allow empty files to be uploaded;
              if (file) return true;
              return false;
            }
          )
          .test("fileSize", "The file is too large", (file) => {
            //if u want to allow only certain file sizes
            return file && file.size <= 2000000;
          }),
    })
    .required();

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (id) {
      getBlogDetails(id);
    }
  }, [id]);

  const onImgLoad = ({ target: img }) => {
    if (img.offsetWidth === 256 && img.offsetHeight === 256) {
        setValue("image", image)
    }
    else {
        setValue("image", null)
    }
}

  const getBlogDetails = async (id) => {
    try {
      setLoading(true);
      let blog = await customAxios.get(`admin/blog/${id}`);

      let datas = blog?.data?.data;

      let data = {
        title: datas?.title,
        description: datas?.description,
        image: datas?.image,
        videourl: datas?.videourl,
      };

      console.log({ data });

      reset(data);
    } catch (error) {
      toast.error(error);
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    let url = "admin/create-blog";
    if (id) {
      url = `admin/edit-blog`;
      data["id"] = id;
    }

    setLoading(true);

    try {
      let datas = await customAxios.post(url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success(datas.data.message);
      reset({
        title: "",
        description: "",
        image: "",
        videourl: "",
      });
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
      onCloseModal();
    }
  };

  const clearclose = () => {
    reset({
      title: "",
      description: "",
      image: "",
      videourl: "",
    });
    onCloseModal();
  };

  return (
    <Modal isOpen={openModal} toggle={clearclose}>
      <ModalHeader toggle={clearclose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Add Blog
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <CustomInput
            fieldLabel="Title :"
            control={control}
            error={errors.title}
            fieldName="title"
          />
          <CustomTextArea
            fieldLabel="Description :"
            control={control}
            error={errors.description}
            fieldName="description"
            type="textarea"
          />
          <ImagesInput
            fieldLabel="Image : 256px * 256px"
            control={control}
            error={errors.image}
            fieldName="image"
            type="file"
            onChangeValue={(file) => {
              setImage(file)
            }}
          />

			{image && <div><img onLoad={onImgLoad} src={URL.createObjectURL(image)} width={100} height={100} style={{borderRadius: 5}}/></div>}

          <CustomInput
            fieldLabel="Video URL:"
            control={control}
            error={errors.videourl}
            fieldName="videourl"
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          {loading ? "Loading..." : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddBlog;
