import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Inline styles for the toggle switch
const toggleSwitchStyle = (isActive, disabled) => ({
  display: 'flex',
  alignItems: 'center',
  cursor: disabled ? 'not-allowed' : 'pointer',
  width: '50px', // Fixed width
  height: '25px', // Fixed height
  backgroundColor: disabled ? '#ddd' : isActive ? '#4caf50' : '#B2BEB5', // Colors based on state and disabled status
  borderRadius: '12px', // Rounded corners
  position: 'relative',
  padding: '2px',
  boxSizing: 'border-box', // Ensure padding doesn't affect size
  transition: 'background-color 0.3s',
});

const sliderStyle = (isActive, disabled) => ({
  width: '25px', // Fixed width of the slider
  height: '25px', // Fixed height of the slider
  backgroundColor: 'white',
  borderRadius: '50%',
  position: 'absolute',
  left: isActive ? 'calc(100% - 25px)' : '2px', // Right-aligned if active, left-aligned if inactive
  transition: 'left 0.3s', // Transition for smooth movement
  cursor: disabled ? 'not-allowed' : 'pointer', // Cursor style for disabled state
});

const ToggleSwitch = ({ isActive, onToggle, disabled }) => {
  const [localActive, setLocalActive] = useState(isActive);

  useEffect(() => {
    setLocalActive(isActive);
  }, [isActive]);

  const handleToggle = () => {
    if (!disabled) {
      const newStatus = !localActive;
      setLocalActive(newStatus);
      onToggle(newStatus); // Call the parent callback with the new state
    }
  };

  return (
    <div
      onClick={handleToggle}
      style={toggleSwitchStyle(localActive, disabled)}
    >
      <div
        style={sliderStyle(localActive, disabled)}
      />
    </div>
  );
};

// PropTypes validation
ToggleSwitch.propTypes = {
  isActive: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

// Default props
ToggleSwitch.defaultProps = {
  disabled: false,
};

export default ToggleSwitch;
