import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import SelectInput from "../common/SelectInput";
import {
  EditorderStatus,
  getOrderStatus,
} from "../../redux/actions/OrderActions";
import CustomInput from "../common/CustomInput";
import PropTypes from 'prop-types';

const OrderStatusModal = ({ openModal, onCloseModal, data }) => {
  const dispatch = useDispatch();
  const { orderstatusList } = useSelector((state) => state.orders);
  const [options, setOptions] = useState([]);
  const [paymentStatus] = useState([
    { value: "Pending", label: "Pending" },
    { value: "Paid", label: "Paid" },
    { value: "Failed", label: "Failed" },
  ]);
  const [statusName, setStatusName] = useState("");

  const [loading, setLoading] = useState(false);

  // Define regex patterns for validation
  const alphanumericRegex = /^[a-zA-Z0-9-_]+$/;
  // const alphanumericWithSpecialCharsRegex = /^[a-zA-Z0-9=\/\-?\.:\[\]\\]+$/;
  const alphanumericWithSpecialCharsRegex = /^[a-zA-Z0-9=\/\-?\.:\[\]\\#]+$/;


  // Define the validation schema based on the status
  const schema = yup.object().shape({
    ...(statusName === "Shipped" && {
      consignmentNumber: yup
        .string()
        .nullable()
        .required("Consignment Number is required")
        .matches(
          alphanumericRegex,
          "Consignment Number must be alphanumeric and can include '-' and '_'"
        )
        .min(3, "Consignment Number must be at least 3 characters long")
        .max(15, "Consignment Number cannot exceed 15 characters"),
      consignmentLink: yup
        .string()
        .nullable()
        .required("Consignment Link is required")
        .matches(
          alphanumericWithSpecialCharsRegex,
          "Consignment Link must be valid"
        )
        .min(3, "Consignment Link must be at least 3 characters long")
        .max(250, "Consignment Link cannot exceed 250 characters"),
    }),
  });
  const handleStatusChange = (selectedOption) => {
    const selectedStatus = selectedOption?.value;
    setStatusName(selectedStatus);
    setValue("Status", selectedOption);
  };
  useEffect(() => {
    if (orderstatusList) {
      let statuss = orderstatusList.map((st) => {
        return {
          label: st.name,
          value: st.name,
        };
      });

      setOptions(statuss);
    }
  }, [orderstatusList]);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
  });

  
  useEffect(() => {
    if (data) {
      reset({
        Status: options.find((res) => res?.value.toLowerCase() === data?.orderStatus.toLowerCase()),
        paymentStatus: paymentStatus.find((res) => res?.value.toLowerCase() === data?.paymentStatus.toLowerCase()),
        consignmentNumber: data?.consignmentNumber,
        consignmentLink: data?.consignmentLink,
      });
      setStatusName(data?.orderStatus);
    }
  }, [data, options, paymentStatus, reset]);
  

  useEffect(() => {
    dispatch(getOrderStatus());
  }, []);

  const SubmitForm = (datas) => {
    setLoading(true);
    // Ensure Status is correctly handled
  const orderStatusValue = datas.Status ? datas.Status.value : data?.orderStatus;
  

    let val = {
      orderID: data?._id,
      orderStatus: orderStatusValue,
      paymentStatus: datas?.paymentStatus?.value,
      consignmentNumber: datas?.consignmentNumber,
      consignmentLink: datas?.consignmentLink,
    };
    dispatch(EditorderStatus(val)).finally(() => setLoading(false));
    onCloseModal();
  };

  useEffect(() => {
    if (!openModal) {
      reset(); // Reset the form state when the modal is closed
      clearErrors(); // Clear any form errors
    }
  }, [openModal, reset, clearErrors]);

  return (
    <Modal isOpen={openModal} toggle={onCloseModal}>
      <ModalHeader toggle={onCloseModal}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          Edit Status
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <SelectInput
            fieldLabel={<span>Status <span style={{ color: 'red' }}>*</span></span>}
            control={control}
            error={errors.Status}
            fieldName="Status"
            options={options}
            onChangeValue={handleStatusChange}
            selectvalue={options.find(option => option.value === statusName)}
          />
          {statusName === "Shipped" && (
            <>
              <CustomInput
                fieldLabel={<span>Consignment Number <span style={{ color:'red' }}>*</span></span>}
                control={control}
                error={errors.consignmentNumber}
                fieldName="consignmentNumber"
              />
              <CustomInput
                fieldLabel={<span>Consignment Link <span style={{ color:'red' }}>*</span></span>}
                control={control}
                error={errors.consignmentLink}
                fieldName="consignmentLink"
              />
            </>
          )}
          <SelectInput
            fieldLabel={<span>Payment Status <span style={{ color:'red' }}>*</span></span>}
            control={control}
            error={errors.paymentStatus}
            fieldName="paymentStatus"
            options={paymentStatus}
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          type="button"
          color="primary"
          disabled={loading ? true : false}
          onClick={handleSubmit(SubmitForm)}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

// Define propTypes for the component
OrderStatusModal.propTypes = {
  openModal: PropTypes.bool.isRequired, // openModal should be a boolean
  onCloseModal: PropTypes.func.isRequired, // onCloseModal should be a function
  data: PropTypes.shape({ // data should be an object with specific properties
    _id: PropTypes.string.isRequired,
    orderStatus: PropTypes.string.isRequired,
    paymentStatus: PropTypes.string.isRequired,
    consignmentNumber: PropTypes.string,
    consignmentLink: PropTypes.string,
  }).isRequired,
};

export default OrderStatusModal;
