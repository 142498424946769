export const CREATE_ORDER_SUCCESS = "CREATEORDERSUCCESS"
export const CREATE_ORDER_FAIL = "CREATEORDERFAIL"
export const GET_STATUS_ORDER_SUCCESS="GET_STATUSORDERSUCCESS"
export const GET_STATUS_ORDER_FAIL ="GET_STATUSORDERFAIL"
export const UPDATE_ORDER_SUCCESS="UPDATEORDERSUCCESS"
export const UPDATE_ORDER_FAIL="UPDATEORDERFAIL"
export const GET_ORDER_SUCCESS="GETORDERSUCCESS"
export const GET_ORDER_FAIL="GETORDERFAIL"
export const GET_SINGLE_ORDER_SUCCESS="GETSINGLEORDERSUCCESS"
export const GET_SINGLE_ORDER_FAIL="GETSINGLEORDERFAIL"
export const EDIT_STATUS_SUCCESS="EDITSATUSSUCCESS"
export const EDIT_STATUS_FAIL="EDITSTATUSFAIL"