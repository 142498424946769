export const CREATE_PROFILE_SUCCESS = "CREATEPROFILESUCCESS";
export const CREATE_PROFILE_FAIL = "CREATEPROFILEFAIL";
export const DEL_PROFILE_SUCCESS = "DELPROFILESUCCESS";
export const DEL_PROFILE_FAIL = "DELPROFILEFAIL";
export const UPDATE_PROFILE_SUCCESS = "UPDATEPROFILESUCCESS";
export const UPDATE_PROFILE_FAIL = "UPDATEPROFILEFAIL";
export const GET_PROFILE_SUCCESS = "GETPROFILESUCCESS";
export const GET_PROFILE_FAIL = "GETPROFILEFAIL";

/*****************NOTIFICATION************** */
export const CREATE_Notification_SUCCESS = "CREATENotificationSUCCESS";
export const CREATE_Notification_FAIL = "CREATENotificationFAIL";
export const DEL_Notification_SUCCESS = "DELNotificationSUCCESS";
export const DEL_Notification_FAIL = "DELNotificationFAIL";
export const UPDATE_Notification_SUCCESS = "UPDATENotificationSUCCESS";
export const UPDATE_Notification_FAIL = "UPDATENotificationFAIL";
export const GET_Notification_SUCCESS = "GETNotificationSUCCESS";
export const GET_Notification_FAIL = "GETNotificationFAIL";

/******************SLIDER************************ */
export const CREATE_SLIDER_SUCCESS = "CREATESLIDERSUCCESS";
export const CREATE_SLIDER_FAIL = "CREATESLIDERFAIL";
export const DEL_SLIDER_SUCCESS = "DELSLIDERSUCCESS";
export const DEL_SLIDER_FAIL = "DELSLIDERFAIL";
export const UPDATE_SLIDER_SUCCESS = "UPDATESLIDERSUCCESS";
export const UPDATE_SLIDER_FAIL = "UPDATESLIDERFAIL";
export const GET_SLIDER_SUCCESS = "GETSLIDERSUCCESS";
export const GET_SLIDER_FAIL = "GETSLIDERFAIL";

/********************CUSTOMER LIST**********************/
export const GET_CUTOMER_LIST_SUCCESS ="GETCUTOMERLISTSUCCESS"
export const GET_CUSTOMER_LIST_FAIL="GETCUSTOMERLISTFAIL"

/********************CUSTOMER ENABLE DISABLE**********************/
export const CUSTOMER_ENABLE_SUCCESS ="CUTOMERENABLESUCCESS"
export const CUSTOMER_ENABLE_FAIL="CUSTOMERENABLEFAIL"
