import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Col,
  Form,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomInput from "../common/CustomInput";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";

const AddRider = ({ openModal, onCloseModal, title, data }) => {
  const [loading, setLoading] = useState(false);

  const schema = yup.object({
    name: yup
      .string()
      .matches(/^[A-Za-z\s]+$/, "Rider Name must contain only alphabets")
      .max(40, "Rider Name must be at most 40 characters")
      .required("Rider Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email address"),
    mobile: yup
      .string()
      .required("Phone number is required")
      .test("isMobile", "Invalid phone number format", (value) => {
        const mobileRegex = /^[+]?[0-9]{8,16}$/; // Adjust regex for your specific needs
        return mobileRegex.test(value);
      }),
    password: yup
      .string()
      .required("Password is required")
      .test("lowercase", "Contain at least one lowercase letter", (value) => /[a-z]/.test(value))
      .test("number", "Contain at least one number", (value) => /[0-9]/.test(value))
      .test("uppercase", "Contain at least one uppercase letter", (value) => /[A-Z]/.test(value))
      .test("special", "Contain at least one special character", (value) =>
        /[!@#$%^&=*()_+{}\[\]:;<>,.?~\\|]/.test(value)
      )
      .min(8, "Password at least 8 character long"),
    confPasswd: yup
      .string()
      .oneOf([yup.ref("password"), null], "Password must match")
      .required("Confirm Password required"),
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      mobile: "",
      role: "rider",
      password: "",
    },
  });

  const clearclose = () => {
    onCloseModal();
    reset();
  };

  useEffect(() => {
    if (data) {
      reset({
        name: data?.name,
        email: data?.email,
        mobile: data?.mobile,
        role: data?.role,
        password: data?.password,
      });
    } else {
      reset({
        name: '',
        email: '',
        mobile: '',
        role: 'rider',
        password: '',
      });
    }
  }, [data, openModal]);

  const createRider = async (datas) => {
    try {
      setLoading(true);
      let rider;
      if (data) {
        let updateData = {
          name: datas?.name,
          mobile: datas?.mobile,
          id: data?._id,
        };
        rider = await customAxios.post(`admin/update-user`, updateData);
      } else {
        rider = await customAxios.post(`admin/create-rider`, datas);
      }

      if (rider?.data?.message === "New Rider added!") {
        toast.success("New Rider Created successfully");
        reset({
          name: "",
          email: "",
          mobile: "",
          role: "rider",
          password: "",
        });
        onCloseModal();
      } else {
        toast.error(rider?.data?.message);
        reset({
          name: "",
          email: "",
          mobile: "",
          role: "rider",
          password: "",
        });
        onCloseModal();
      }
    } catch (error) {
      toast.error(error?.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={openModal} toggle={clearclose} size="md">
      <ModalHeader toggle={clearclose}>
        <h5 className="modal-title f-w-600" id="exampleModalLabel2">
          {title}
        </h5>
      </ModalHeader>
      <ModalBody>
        <Form>
          <Col>
            <CustomInput
              fieldLabel="Name :"
              control={control}
              error={errors.name}
              fieldName="name"
            />
          </Col>
          {!data && (
            <Col>
              <CustomInput
                fieldLabel="Email :"
                control={control}
                error={errors.email}
                fieldName="email"
              />
            </Col>
          )}

          <Col>
            <CustomInput
              fieldLabel="Mobile:"
              control={control}
              error={errors.mobile}
              fieldName="mobile"
            />
          </Col>
          {!data && (
            <>
              <Col>
                <CustomInput
                  fieldLabel="Password:"
                  control={control}
                  error={errors.password}
                  fieldName="password"
                  type={"password"}
                  eye
                />
              </Col>

              <Col>
                <CustomInput
                  fieldLabel="Confirm Password:"
                  control={control}
                  error={errors.confPasswd}
                  fieldName="confPasswd"
                  type={"password"}
                  eye
                />
              </Col>
            </>
          )}

          {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          disabled={loading}
          type="button"
          color="primary"
          onClick={handleSubmit(createRider)}
        >
          {loading ? "Saving..." : !data ? "Save" : "Update"}
        </Button>
        {/* <Button
                    type="button"
                    color="secondary"
                    onClick={() =>{ 
                        onCloseModal()
                        reset()
                    }}
                >
                    Close
                </Button> */}
      </ModalFooter>
    </Modal>
  );
};

export default AddRider;
