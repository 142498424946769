import React, { Fragment } from "react";
import { ArrowLeft } from "react-feather";
import Slider from "react-slick";
import stats from "../../assets/images/dashboard/stats.png";
import "../../assets/scss/slick.scss";
import "../../assets/scss/slick-theme.scss";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { useLocation } from 'react-router-dom';
import Forgotpswd from "./Forgotpswd";


const ForgotpswdMain = () => {
    let location = useLocation();
    // console.log(location.pathname, "location path.....")


    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
    };
    return (
        <Fragment>
            <div className="page-wrapper">
                <div className="authentication-box">
                    <Container>
                        <Row>
                            <Col className="col-md-5 p-0 card-left">
                            <Card className="bg-primary">
									<div className="svg-icon">
										<img alt="" src={stats} className="Img-fluid" />
									</div>
									<Slider className="single-item" {...settings}>
										<div>
											<div>
												<h3>Welcome to Pearl Admin</h3>
												<p>
                                                Welcome to the eCommerce Admin Panel! Access key features and tools to manage your online store efficiently and keep your customers satisfied.
												</p>
											</div>
										</div>
										<div>
											<div>
												<h3>Product Management</h3>
												<p>
                                                Easily manage your product catalog by adding, editing, and organizing products. Keep your listings accurate and appealing to attract customers.
												</p>
											</div>
										</div>
										<div>
											<div>
												<h3>Order Management</h3>
												<p>
                                                Track and process customer orders efficiently. Manage order statuses, shipping, returns, and other aspects with comprehensive search and filter options.
												</p>
											</div>
										</div>
									</Slider>
								</Card>
                            </Col>
                            <Col className="col-md-5 p-0 card-right">
                                <Card className="tab2-card">
                                    <CardBody>

                                        <Forgotpswd />





                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        {/* <a
                            href="https://diginestsolutions.com/"
                            target="_blank"
                            rel="noreferrer"
                            className="btn btn-primary back-btn"
                        >
                            <ArrowLeft />
                            back
                        </a> */}
                    </Container>
                </div>
            </div>
        </Fragment>
    )
}

export default ForgotpswdMain