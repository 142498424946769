import React from "react";
import { useSelector } from "react-redux";
import man from "../../../assets/images/dashboard/man.png";

const UserPanel = ({user}) => {

	const { profilelist } = useSelector(state => state.settings)
	return (
		<div>
			<div className="sidebar-user text-center">
				<h6 className="mt-1 f-14">{user?.name}</h6>
				<p>{user?.role}</p>
			</div>
		</div>
	);
};

export default UserPanel;
