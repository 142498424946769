import React from 'react'
import DataTable from 'react-data-table-component'

const DataTables = ({ columns, row, pagination, noRowsPerPage }) => {
  return (
    <DataTable
        data={row}
        columns={columns}
        striped={true}
        center={true}
        pagination={pagination}
        paginationPerPage={noRowsPerPage}
        //paginationServer="https://mmdcart.diginestsolutions.in/public/api/admin/orders"
    />
  )
}

export default DataTables