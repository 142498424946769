import React from 'react'
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { Controller } from "react-hook-form";

const ImagesInput = ({ fieldName, control,fieldLabel, placeholder, error, type, max, onChangeValue }) => {
    return (
        <>
            <FormGroup>
                <Label
                    htmlFor="recipient-name"
                    className="col-form-label"
                >
                    {fieldLabel}
                </Label>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field: { onChange, onBlur, value } }) =>
                        <Input
                            onBlur={onBlur}
                            aria-invalid={error ? "true" : "false"}
                            className="form-control"
                            placeholder={placeholder}
                            id="exampleInputEmail1"
                            type={type ? type : "text"}
                            maxLength={max}
                            onChange={onChangeValue ? (e) => onChangeValue(e.target.files[0]) : (e) => onChange(e.target.files[0])}
                            accept="images/*"
                        />}
                />
                {error && <p role="alert" style={{ color: "red", display: "flex", flexDirection: "start", paddingLeft: "10px" ,fontSize:"12px"}}>{error?.message}</p>}
            </FormGroup>
        </>
    )
}

export default ImagesInput