
import React, { Fragment, useState } from "react";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import CustomInput from "../common/CustomInput";
import Customotp from "./Customotp";
import customAxios from "../../customAxios";
import { toast } from "react-toastify";
import Reactotron from "reactotron-react-js"
import Spinner from "../common/Spinner";

function Forgotpswd() {

	const [showOtp, setShowOtp] = useState(false)
	const [loading, setLoading] = useState(false)
	const [sendEmail, setSendEmail] = useState('')

	const schema = yup.object({
		email: yup.string().email().required(),

	}).required();

	const { register, formState: { errors }, handleSubmit, control } = useForm({
		resolver: yupResolver(schema)
	});

	const onSubmit = async (data) => {
        try {
			setLoading(true)
            const res = await customAxios.post('customer/forgot-customer-password-otp', data)
			if( res?.status === 200 || 201) {
				toast.success('OTP Sent Successfully')
				setShowOtp(true)
				setSendEmail(data?.email)
			}
        } catch (err) {
            toast.error(err.message)
        }
		finally {
            setLoading(false)
        }
    }



	return (
		<Fragment>
			<div style={{ display: "flex", flexDirection: "column", height: "400px" }} >
				<Form className="form-horizontal " >
					<div style={{ paddingBottom: "15px" }}>
						<h1 style={{ fontSize: "22px" }}>Forget Your Password ?</h1>
					</div>
					<CustomInput
						fieldName={"email"}
						placeholder="Type your email"
						error={errors.email}
						control={control}
					/>
					{showOtp === true ? null : (<div className="form-button pull-right">
						<a href="/#" className="btn btn-default forgot-pass p-0" onClick={handleSubmit(onSubmit)}>
							{loading ? <Spinner />: "Get Your OTP"}
						</a>
					</div>)}
				</Form>
				<div style={{ paddingTop: "10px" }}>

					{ showOtp && <Customotp emailMain={sendEmail} /> }

				</div>
			</div>
		</Fragment>
	)
}

export default Forgotpswd