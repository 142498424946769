import React, { useEffect, useMemo, useState } from "react";
import { LoadScript, GoogleMap, useLoadScript } from "@react-google-maps/api";
import Drawing from "./Drawing";
import Polygon from "./Polygon";
import { MAPS } from "../../../../config/constant";


const LIBRARIES = ["drawing"];

const Maps = ({ onPolygonComplete }) => {

    const [center, setCenter] = useState(null)

    const libraries = useMemo(() => ['drawing'], []);

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: MAPS,
        libraries,
        region: 'in'
    });

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setCenter(pos)
            },
            () => {
                //handleLocationError(true, infoWindow, map.getCenter()!);
            }
        );
    }, [])




    if (!isLoaded) {
        return <div style={{ width: '100%', height: 300, justifyContent: 'center', alignItems: 'center', display: 'flex' }}>Loading...</div>
    }


    return (

        <div style={{ padding: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <GoogleMap
                mapContainerClassName="App-map"
                center={center}
                zoom={12}
                mapContainerStyle={{ width: '100%', minHeight: 300 }}
                version="weekly"
                on
            >
                <Drawing onComplete={onPolygonComplete} />
            </GoogleMap>
        </div>
    );
};

export default Maps;
