import React, { Fragment, useState, useEffect } from 'react'
import ModalSlider from './ModalSlider'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import Breadcrumb from "../../common/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { DELNOTIFICATION, delSlider, GETNOTIFICATION, GetSlider } from '../../../redux/actions/SettingActions';
import DataTables from '../../common/DataTables';
import { toast } from 'react-toastify';
import { RESET_ERROR } from "../../../redux/constants/userConstants";
const AddSlider = () => {

    const dispatch = useDispatch()
    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    const { getsliderlist, sliderdeleted,sliderlistcreated,sliderupdated,error } = useSelector(state => state.settings)
    // console.log(getsliderlist, "getsliderlistgetsliderlist")
    const [open, setOpen] = useState(false);
    const [openedit, setOpenEdit] = useState(false);
    const [activeData, setActiveData] = useState(null)

    const customId = "delSlide";

    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Image")}</b>,
            header: <b>{Capitalize("Image")}</b>,
            cell: (row, index) => (
                <>
                    <span>
                        <img style={{ width: 40, height: 40 }} src={row?.image}></img>
                    </span>
                </>

            )
        },
        {
            name: <b>{Capitalize("Slider Name ")}</b>,
            header: <b>{Capitalize("Slider Name ")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Order")}</b>,
            header: <b>{Capitalize("Order")}</b>,
            selector: row => row?.order,
            style: {
                textAlign: "center",
            },
        },

        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (
                <>
                    <span onClick={() => handleDelete(row._id)} >
                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];

    const onOpenModal = () => {
        setOpen(true);
    };


    const handleEdit = (data) => {
        setActiveData(data)
        setOpen(true);
    }

    const handleDelete = (id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            dispatch(delSlider(id))
        }

       

    }
    const onCloseModal = () => {
        setOpen(false);

    };
    useEffect(() => {
        dispatch(GetSlider())

    }, [])

    useEffect(() => {
        if (sliderlistcreated) {
            toast.success("Slider created successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        onCloseModal();
    }, [sliderlistcreated])
    
    useEffect(() => {
        if (sliderdeleted) {
            toast.success("Slider Deleted successfully",{toastId: customId} );
            dispatch({
                type: RESET_ERROR
            })
        }
    }, [sliderdeleted])
    useEffect(() => {
        if (sliderupdated) {
            toast.success("Slider Updated successfully");
            dispatch({
                type: RESET_ERROR
            })
        }
        onCloseModal();
    }, [sliderupdated])
    useEffect(() => {
        if (error) {
            toast.error(`${error?.message}`);
            dispatch({
                type: RESET_ERROR
            })
        }
        onCloseModal();
    }, [error])



    return (
        <Fragment>
            <Breadcrumb title="Slider" parent="settings" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Slider</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="btn-popup pull-right">
                                    <Button
                                        type="button"
                                        color="secondary"
                                        onClick={onOpenModal}
                                        data-toggle="modal"
                                        data-original-title="test"
                                        data-target="#exampleModal"
                                    >
                                        Add Slider
                                    </Button>

                                </div>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {getsliderlist && <DataTables
                                        columns={columns}
                                        row={getsliderlist ? getsliderlist : []}
                                    />}

                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <ModalSlider openModal={open} onCloseModal={() => {
                setActiveData(null)
                setOpen(false)
            }} data={activeData} setActiveData={setActiveData}
            />


        </Fragment>
    )
}

export default AddSlider