import React, { Fragment, useEffect, useState } from "react";
import { ShoppingBag, Download, AlertCircle } from "react-feather";
import { Media } from "reactstrap";

import customAxios from '../../../customAxios';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getSingleOrderList } from "../../../redux/actions/OrderActions";


const Notification = ({ item, onClick }) => {
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const NavigateToOrder = (res) => {
		if (res?.screen === "order") {
			dispatch(getSingleOrderList(res?.order?._id))
			navigate(`/order/orderCustomerDetails/${res?.order?._id}`);
		}

	}



	return (
		<Fragment>
			<ul className="notification-dropdown onhover-show-div p-0" style={{ maxHeight: '300px', overflowY: 'auto' }} >
				<li>
					Notification{" "}
					<span className="badge rounded-pill badge-primary pull-right">{item?.count}</span>
				</li>
				<li style={{ overflowY: 'auto', maxHeight: 'calc(100% - 30px)' }} >
					<div style={{ overflowY: 'auto', maxHeight: '100%' }}>
						{item?.data?.map((res, index) => (
							<Media key={index} onClick={() => NavigateToOrder(res)}>
								<Media body>
									<h6 className="mt-0">
										{res?.name}
									</h6>
									<p className="mb-0">{res?.description}</p>
								</Media>
							</Media>
						))}
					</div>
				</li>
			</ul>
		</Fragment>

	);
};

export default Notification;
