import React, { Fragment, useState } from "react";
import { Button } from "react-bootstrap";
import { Search } from "react-feather";
import { Form, FormGroup, Input } from "reactstrap";

const SearchHeader = ({filterText, onFilter, onClear}) => {
	const [searchbar, setSearchbar] = useState(false);

	const handleSearchClick = () => {
		setSearchbar(!searchbar);
	};

	return (
		<Fragment>
			<Form className="form-inline search-form">
				<FormGroup>
					<Input
						className={"form-control-plaintext " + (searchbar ? "open" : "")}
						style={{ borderWidth: 0.2, borderColor: 'gray', width: 300, paddingLeft: 5 }}
						type="search"
						placeholder="Search.."
						value={filterText}
						onChange={(e) => {
							if(e.target.value === ""){
								onClear()
							}
							else{
								onFilter(e)
							}
						}}
					/>
					{/* <span
						className="d-sm-none mobile-search"
						onClick={() => handleSearchClick()}
					>
						<Search />
					</span> */}
				</FormGroup>
			</Form>
		</Fragment>
	);
};

export default SearchHeader;
