import React, { useState, useEffect } from 'react'
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import CustomInput from '../../common/CustomInput';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string, mixed } from "yup"
import * as yup from 'yup'
import SelectInput from '../../common/SelectInput';
import TimePicker from 'react-time-picker';
import customAxios from '../../../customAxios';
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";

const AddDeliverySlotModal = ({ openModal, onCloseModal, data, setActiveData, title ,getList}) => {

    function getCurrentTime() {
        const now = new Date();
        const hours = now.getHours().toString().padStart(2, '0');
        const minutes = now.getMinutes().toString().padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    useEffect(() => {
        getCurrentTime()
    }, [])

    const currentTime = getCurrentTime()
    const [value, onChange] = useState(currentTime);
    const [valueTo, onChangeTo] = useState(currentTime);
    const [loading, setLoading] = useState(false);


  
    const days = [
        { label: 'Sunday', value: 0 },
        { label: 'Monday', value: 1 },
        { label: 'Tuesday', value: 2 },
        { label: 'Wednesday', value: 3 },
        { label: 'Thursday', value: 4 },
        { label: 'Friday', value: 5 },
        { label: 'Saturday', value: 6 }
    ];
    const schema = yup.object({
        // day: yup.string().required('Required')
    })

    const { register, handleSubmit, setFieldValue, control, formState: { errors }, reset, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {

        }
    });


    const clearclose = () => {
        onCloseModal()
        reset()
    }

    const Submit = async (data) => {
      if(!value && !value){
        toast.warn(`Time field is Required`);
      }

        let DAYTIME = {
            day: data?.day?.label,
            fromTime: value,
            toTime: valueTo
        }


        try {
            setLoading(true);
            await customAxios.post('admin/add-deliveryslots', { ...DAYTIME })
            getList()
            toast.success(`Created Successfully`);
            clearclose()
        } catch (err) {
            toast.error(err.message);
        } finally {
            setLoading(false);
        }

    }




    return (
        <Modal isOpen={openModal} toggle={clearclose} size="lg">
            <ModalHeader toggle={clearclose}>
                <h5
                    className="modal-title f-w-600"
                    id="exampleModalLabel2"
                >
                    {title}
                </h5>
            </ModalHeader>
            <ModalBody>
                <Form>
                    <Row>
                        <Col xl={4}>

                            <SelectInput
                                fieldLabel='Select Days'
                                control={control}
                                error={errors.day}
                                fieldName='day'
                                isMultiple={false}
                                options={days}

                            />
                        </Col>
                        <Col xl={4}>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label
                                    htmlFor="recipient-name"
                                    className="col-form-label"
                                >
                                    {'From Time'}
                                </Label>
                                <TimePicker onChange={onChange} value={value} size={150} format={"HH:mm"} />
                            </div>

                        </Col>
                        <Col xl={4}>

                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label
                                    htmlFor="recipient-name"
                                    className="col-form-label"
                                >
                                    {'To Time'}
                                </Label>
                                <TimePicker onChange={onChangeTo} value={valueTo} size={150} format={"HH:mm"} />
                            </div>

                        </Col>
                    </Row>

                    {/* <span style={{ color: "red" }}>Image resolution should be 768px * 1000px</span> */}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button
                    type="button"
                    color="primary"
                    onClick={handleSubmit(Submit)}
                    disabled={loading}
                >
                    {loading ? "Saving..." : "Save"}
                </Button>

            </ModalFooter>

        </Modal>
    )
}

export default AddDeliverySlotModal