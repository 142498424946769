import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTables from "../common/DataTables";
import { useDispatch, useSelector } from "react-redux";
import { CustomerEnableDisable, GetCustomerList } from "../../redux/actions/SettingActions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ToggleSwitch from "../common/ToggleSwitch";
import { toast } from "react-toastify";
import { RESET_ERROR } from "../../redux/constants/userConstants";

const CustomerList = () => {
  const dispatch = useDispatch();
  const { CustomerListdata = [], customerenabled } = useSelector(state => state.settings);

  const [localStatus, setLocalStatus] = useState({});

  useEffect(() => {
    if (customerenabled) {
      toast.success("Customer status updated successfully");
      dispatch({ type: RESET_ERROR });
    }
  }, [customerenabled, dispatch]);

  useEffect(() => {
    dispatch(GetCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (CustomerListdata) {
      // Initialize localStatus with current data
      const statusMap = {};
      CustomerListdata.forEach(row => {
        statusMap[row._id] = row.status;
      });
      setLocalStatus(statusMap);
    }
  }, [CustomerListdata]);

  const Capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);

  const columnStyles = {
    width: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const enabledTooltip = (
    <Tooltip id="enabled-tooltip" className="custom-tooltip">
      Enabled
    </Tooltip>
  );
  
  const disabledTooltip = (
    <Tooltip id="disabled-tooltip" className="custom-tooltip">
      Disabled
    </Tooltip>
  );

  const handleToggle = (rowId, currentStatus) => {
    const newStatus = currentStatus === "active" ? "inactive" : "active";
    const formdata = {
      id: rowId,
      status: newStatus,
    };

    // Update local status for immediate feedback
    setLocalStatus(prev => ({ ...prev, [rowId]: newStatus }));

    dispatch(CustomerEnableDisable(formdata))
      .then(() => {
        dispatch(GetCustomerList());
      })
      .catch(() => {
        // Revert local status on error
        setLocalStatus(prev => ({ ...prev, [rowId]: currentStatus }));
      });
  };

  let columns = [
    {
      name: <b>{Capitalize("S.No")}</b>,
      header: <b>{Capitalize("S.No")}</b>,
      cell: (row, index) => index + 1,
    },
    {
      name: <b>{Capitalize("Name")}</b>,
      header: <b>{Capitalize("Name")}</b>,
      selector: row => row?.name,
      style: { textAlign: "center" },
    },
    {
      name: <b>{Capitalize("Email")}</b>,
      header: <b>{Capitalize("Email")}</b>,
      selector: row => row?.email,
      style: { textAlign: "center" },
    },
    {
      name: <b>{Capitalize("Address")}</b>,
      header: <b>{Capitalize("Address")}</b>,
      cell: (row) => (
        <span>
          {row?.address?.addressLine1},
          {row?.address?.addressLine2},
          {row?.address?.country},
          {row?.address?.city},
          {row?.address?.state},
          {row?.address?.zip},
        </span>
      ),
    },
    {
      name: <b>{Capitalize("Mobile")}</b>,
      header: <b>{Capitalize("Mobile")}</b>,
      selector: row => row?.mobile,
      style: { textAlign: "center" },
    },
    {
      name: <b>{Capitalize("Status")}</b>,
      header: <b>{Capitalize("Status")}</b>,
      cell: (row) => (
        <OverlayTrigger
          placement="top"
          overlay={localStatus[row._id] === "active" ? enabledTooltip : disabledTooltip}
        >
          <div style={columnStyles}>
            <ToggleSwitch
              isActive={localStatus[row._id] === "active"}
              onToggle={() => handleToggle(row._id, localStatus[row._id])}
            />
          </div>
        </OverlayTrigger>
      ),
      style: { textAlign: "center" },
    },
  ];

  return (
    <Fragment>
      <Breadcrumb title="Customer" parent="Products" />
      <Container fluid="true">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Customer List</h5>
              </CardHeader>
              <CardBody>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  {CustomerListdata && (
                    <DataTables
                      columns={columns}
                      row={CustomerListdata}
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CustomerList;
