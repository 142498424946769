import React, { Fragment, useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Route, Routes, unstable_HistoryRouter, useNavigate } from "react-router-dom"
import Forgotpswd from "../components/auth/Forgotpswd"
import ForgotpswdMain from "../components/auth/ForgotpswdMain"
import Login from "../components/auth/login"
import { getProfile } from "../redux/actions/SettingActions"
import LayoutRoutes from "./LayoutRoutes"
import { FCMToken, onMessageListener } from "../config/firebase"
import { toast } from 'react-toastify';
import ResetPassMain from "../components/auth/ResetPassMain"

const Routers = () => {

    const [token, setToken] = useState(null);
    const [notification, setNotification] = useState({ title: '', body: '' });
    const [isTokenFound, setTokenFound] = useState(false);


    {
        isTokenFound &&
            <h4>👍🏻</h4>
    }
    {
        !isTokenFound &&
            <h4>❗️</h4>
    }


    onMessageListener().then(payload => {
        // console.log({payload},'ORDERRRRRR PALODE')

        // setNotification({title: payload.notification.title, body: payload.notification.body})
        // setData(payload?.data)
        // setShow(true)

        toast(({ closeToast }) => <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div style={{ fontSize: 16, color: 'black', fontWeight: 'bold' }}><h4>{payload?.notification?.title}</h4></div>
            <div><h4>{payload?.notification?.body}</h4></div>
        </div>);

    }).catch(err => console.log('failed: ', err));


    const navigate = useNavigate()
    const dispatch = useDispatch()

    useEffect(() => {
        const onStorage = () => {
            let tokens = localStorage.getItem('token');
            if (tokens) {
                setToken(tokens)
                dispatch(getProfile())
            }
            else {
                setToken(null)
                navigate('/')
            }
        };

        onStorage()

        window.addEventListener('storage', onStorage);

        return () => {
            window.removeEventListener('storage', onStorage);
        };
    }, []);


    FCMToken(setTokenFound);

    return (
        <Fragment>
            <Routes>

                <Route exact path={`${process.env.PUBLIC_URL}/`} element={<Login />} />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/auth/login`}
                    element={<Login />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/auth/forgot`}
                    element={<ForgotpswdMain />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL}/auth/resetPassword`}
                    element={<ResetPassMain />}
                />
                <Route path={`${process.env.PUBLIC_URL}/*`} element={<LayoutRoutes />} />
            </Routes>
        </Fragment>
    )
}

export default Routers