import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { filterOrder, getOrderList } from "../../redux/actions/OrderActions";
import { useNavigate } from "react-router-dom";
import OrderStatusModal from "./OrderStatusModal";
import { RESET_ERROR } from "../../redux/constants/userConstants";
import DataTable from "react-data-table-component";
import SearchHeader from "../common/header_components/searchHeader";
import CustomInput from "../common/CustomInputPast";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Reactotron from "reactotron-react-js"
import * as yup from "yup";

import moment from "moment";
import AssignRiderModal from "./AssignRider";
// getorderlist
const OrderDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { orderList, orderupdated, error } = useSelector(
    (state) => state.orders
  );
  const { loading } = useSelector((state) => state.auth);

  const [datas, setDatas] = useState([]);

  const [open, setOpen] = useState(false);
  const [assign, setAssign] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [activeData, setActiveData] = useState(null);
  const [orderId, setOrderId] = useState('')
  const [rider, setRider] = useState(null)

  Reactotron.log(orderList, "LIST")

  useEffect(() => {
    if (orderList) {
      console.log(orderList,"order list")
      setDatas(orderList);
    }
  }, [orderList]);

  const Capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const Navigatedetailpage = (id) => {
    navigate(`/order/orderCustomerDetails/${id}`);
  };
  const handleEdit = (data) => {
    setActiveData(data);
    setOpen(true);
  };

  const handleAssign = (data) => {
    setOrderId(data?._id)
    if (data?.rider) {
      setRider(data?.rider)
    } else {
      setRider(null)
    }
  };

  useEffect(() => {
    if (orderId) {
      setAssign(true);
    }
  }, [rider, orderId])

  useEffect(() => {
    if (filterText) {
      let datas = orderList?.filter(
        (ord) =>
          ord?.customer?.name
            ?.toLowerCase()
            .includes(filterText.toLowerCase()) ||
            ord?.shippingAddress?.mobile
            ?.toString() // Convert mobile number to string
            .toLowerCase()
            .includes(filterText?.toLowerCase()) ||
          `#ORD${ord?.order_id}`
            ?.toLowerCase()
            ?.includes(filterText?.toLowerCase()) ||
          ord?.orderStatus?.toLowerCase()?.includes(filterText.toLowerCase())
      );

      setDatas(datas);
      console.log(datas,"datas")
    }
  }, [filterText]);

  const schema = yup.object({
    fromDate: yup.string().required("From date required"),
    toDate: yup.string().required("To date required"),
  });

  const {
    register,
    handleSubmit,
    setFieldValue,
    control,
    formState: { errors },
    reset,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setFilterText("");
        setDatas(orderList)
      }
    };

    const filterOrders = (data) => {
      dispatch(filterOrder(data));
    };

    const resetFun = () => {
      window.location.reload();
      dispatch(getOrderList(1));
    };

    return (
      <div
        style={{ display: "flex", justifyContent: "space-between", flex: 1 }}
      >
        <Row style={{ marginRight: 50 }}>
          <Col>
            <CustomInput
              fieldLabel="From Date"
              control={control}
              error={errors.fromDate}
              fieldName="fromDate"
              type={"date"}
            />
          </Col>
          <Col>
            <CustomInput
              fieldLabel="To Date"
              control={control}
              error={errors.toDate}
              fieldName="toDate"
              type={"date"}
            />
          </Col>
          <Col style={{ paddingTop: 35 }}>
            <Button
              type="button"
              color="primary"
              onClick={handleSubmit(filterOrders)}
            >
              Submit
            </Button>
          </Col>
          <Col style={{ paddingTop: 35 }}>
            <Button type="button" color="primary" onClick={resetFun}>
              Reset
            </Button>
          </Col>
        </Row>
        <SearchHeader
          onFilter={(e) => setFilterText(e.target.value)}
          onClear={handleClear}
          filterText={filterText}
        />
      </div>
    );
  }, [filterText]);

  var columns = [
    {
      name: <b>{Capitalize("OrderId")}</b>,
      header: <b>{Capitalize("Order Id")}</b>,
      selector: (row) => `#ORD${row?.order_id}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Order Date")}</b>,
      header: <b>{Capitalize("Order Date")}</b>,
      selector: (row) => `${moment(row?.orderDate).format("DD-MM-YYYY")}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Name")}</b>,
      header: <b>{Capitalize("Name")}</b>,
      selector: (row) => row?.customer?.name,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("mobile")}</b>,
      header: <b>{Capitalize("Mobile No")}</b>,
      selector: (row) => row?.shippingAddress?.mobile,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("payment Type")}</b>,
      header: <b>{Capitalize("payment Type")}</b>,
      selector: (row) => row?.paymentType,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Payment Status")}</b>,
      header: <b>{Capitalize("paymentType")}</b>,
      selector: (row) => {
        if (row?.paymentStatus === "captured") {
          return "paid"; // Use return to send the value
        } else {
          return row?.paymentStatus; // Return the original status
        }
      },
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("total")}</b>,
      header: <b>{Capitalize("total")}</b>,
      selector: (row) => `₹ ${parseFloat(row?.total).toFixed(2)}`,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot Date")}</b>,
      header: <b>{Capitalize("Slot Date")}</b>,
      selector: (row) => {
        const dateFormat = "YYYY-MM-DD HH:mm";
        const parsedDate = moment(row?.slot_date, dateFormat, true);
        if (parsedDate.isValid()) {
          return moment(row?.slot_date, "YYYY-MM-DD HH:mm").format(
            "DD-MM-YYYY"
          );
        } else {
          return "_";
        }
      },
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot FromTime")}</b>,
      header: <b>{Capitalize("Slot FromTime")}</b>,
      selector: (row) => row?.delivery_slot?.fromTime,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Slot ToTime")}</b>,
      header: <b>{Capitalize("Slot ToTime")}</b>,
      selector: (row) => row?.delivery_slot?.toTime,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Order status")}</b>,
      header: <b>{Capitalize("orderStatus")}</b>,
      selector: (row) => {
        if (row?.orderStatus === "Paid") {
          return "created"; // Use return to send the value
        } else {
          return row?.orderStatus; // Return the original status
        }
      },
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Consignment Number")}</b>,
      header: <b>{Capitalize("consignmentNumber")}</b>,
      selector: (row) => row?.consignmentNumber,
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>{Capitalize("Consignment Link")}</b>,
      header: <b>{Capitalize("consignmentLink")}</b>,
      selector: (row) => (<a href={row.consignmentLink} target="_blank" rel="noopener noreferrer" style={{ color: 'blue', textDecoration: 'underline' }}>
        {row.consignmentLink}
      </a>),
      style: {
        textAlign: "center",
      },
    },
    {
      name: <b>Actions</b>,
      id: "delete",
      accessor: () => "delete",
      style: {
        textAlign: 'center',
      },
      cell: (row) => (
        <div>
          <div>
            <span onClick={() => Navigatedetailpage(row?._id)}>
              <i
                className="fa fa-eye"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "#e4566e",
                  cursor: "pointer",
                }}
              ></i>
            </span>

            <span onClick={() => handleEdit(row)}>
              <i
                className="fa fa-pencil"
                style={{
                  width: 35,
                  fontSize: 20,
                  padding: 11,
                  color: "rgb(40, 167, 69)",
                  cursor: "pointer",
                }}
              ></i>
            </span>
          </div>
          {(row?.orderStatus?.toLowerCase() !== "cancelled" && row?.orderStatus?.toLowerCase() !== "delivered") && <div>
           <span onClick={() => handleAssign(row)}>
            <div
              style={{
                background: row?.rider ? "#f71c1b" : "#0f02ff",
                padding: 5,
                borderRadius: 5,
                cursor: "pointer",
                
              }}
            >
              <p style={{ color: "#fff", fontSize: 12, textAlign: 'center' }}>
                {row?.rider ? "RE-ASSIGN" : "ASSIGN"}
              </p>
            </div>
          </span>
          </div>}
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (orderupdated) {
      toast.success(`Order Updated Successfully`);
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [orderupdated]);

  useEffect(() => {
    if (error) {
      toast.error(`${error.message}`);
      dispatch({
        type: RESET_ERROR,
      });
    }
  }, [error]);

  useEffect(() => {
    dispatch(getOrderList(1));
  }, []);

  return (
    <Fragment>
      <Breadcrumb title="Order List" parent="Order" />
      {/* <!-- Container-fluid starts--> */}
      <Container fluid="true">
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>Order Details</h5>
              </CardHeader>
              <CardBody>
                <div className="clearfix"></div>
                <div id="basicScenario" className="product-physical">
                  <Fragment>
                    <DataTable
                      pagination
                      title=""
                      columns={columns}
                      progressPending={loading}
                      progressComponent={
                        <Spinner animation="grow" variant="info" />
                      }
                      data={datas}
                      fixedHeader
                      subHeader
                      subHeaderComponent={subHeaderComponentMemo}
                    />
                  </Fragment>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <OrderStatusModal
        openModal={open}
        onCloseModal={() => {
          setOpen(false);
        }}
        data={activeData}
      />

      <AssignRiderModal
        openModal={assign}
        onCloseModal={() => {
          setAssign(false);
        }}
        data={activeData}
        order_id={orderId}
        rider={rider}
      />
    </Fragment>
  );
};

export default OrderDetails;
