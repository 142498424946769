
import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb";
import { Modal, Button, Card, CardBody, CardHeader, Col, Container, Form, FormGroup, Input, Label, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import DataTables from "../common/DataTables";
import customAxios from "../../customAxios";
import AddRider from "./NewRider";
import { toast } from "react-toastify";

const RiderList = () => {


    const [riderList, setRiderList] = useState([])
    const [open, setOpen] = useState(false)
    const [data, setData] = useState(null)
    
    const customId = "delUser";

    useEffect(() => {
      getRidersList()
    }, [])

    const getRidersList = async() => {
        const data = await customAxios.get(`admin/riderslist`);

        setRiderList(data?.data?.data)

    }


    const handleDelete = async(id) => {
        if (window.confirm("Are you sure you wish to delete this item?")) {
            try {
                let deleteUser = await customAxios.delete(`admin/delete-rider/${id}`)
                if(deleteUser?.data?.message === "User deleted"){
                    toast.success("User deleted successfully",{toastId: customId})
                    getRidersList()
                }
                else{
                    toast.error(deleteUser?.data?.message)
                }
            } catch (error) {
                toast.error(error?.message)
            }
            
            
            //getRidersList()
        }
    }

    const handleEdit = (data) => {
        setData(data)
        setOpen(true)
    }
    

    const Capitalize = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };
    var columns = [
        {
            name: <b>{Capitalize("S.No")}</b>,
            header: <b>{Capitalize("S.No")}</b>,
            cell: (row, index) => index + 1
        },
        {
            name: <b>{Capitalize("Name")}</b>,
            header: <b>{Capitalize("Name")}</b>,
            selector: row => row?.name,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Email")}</b>,
            header: <b>{Capitalize("Email")}</b>,
            selector: row => row?.email,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>{Capitalize("Mobile")}</b>,
            header: <b>{Capitalize("Mobile")}</b>,
            selector: row => row?.mobile,
            style: {
                textAlign: "center",
            },
        },
   
        {
            name: <b>{Capitalize("status")}</b>,
            header: <b>{Capitalize("status")}</b>,
            selector: row => row?.status,
            style: {
                textAlign: "center",
            },
        },
        {
            name: <b>Actions</b>,
            id: "delete",
            accessor: (str) => "delete",
            cell: (row, index) => (

                <>
                    <span onClick={() => handleDelete(row._id)} >

                        <i
                            className="fa fa-trash"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "#e4566e",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                    <span onClick={() => handleEdit(row)}>
                        <i
                            className="fa fa-pencil"
                            style={{
                                width: 35,
                                fontSize: 20,
                                padding: 11,
                                color: "rgb(40, 167, 69)",
                                cursor: "pointer"
                            }}
                        ></i>
                    </span>
                </>
            )
        }
    ];


    const closeModal = () => {
        setOpen(false)
        getRidersList()
        setData(null)
    }



  return (
    <Fragment>
            <Breadcrumb title="Riders" parent="Users" />
            {/* <!-- Container-fluid starts--> */}
            <Container fluid="true">
                <Row>
                    <Col sm="12">
                        <Card>
                            <CardHeader>
                                <h5>Riders List</h5>
                            </CardHeader>
                            <CardBody>
                                <Button style={{ float: "right" }} onClick={() => setOpen(true)}>New Rider</Button>
                                <div className="clearfix"></div>
                                <div id="basicScenario" className="product-physical">
                                    {riderList?.length > 0 && <DataTables
                                        columns={columns}
                                        row={riderList}
                                    />}
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
           <AddRider openModal={open} onCloseModal={closeModal} title={data ? "Edit Rider" : "New Rider"} data={data} />
        </Fragment>
  )
}

export default RiderList