import React from 'react'
import { Button, Form, FormGroup, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import moment from 'moment';

const CustomTextArea = ({ fieldName, control,fieldLabel, placeholder, error, type, max, defaultValue, disabled, minimum, readOnly }) => {
    return (
        <>
            <FormGroup>
                <Label
                    htmlFor="recipient-name"
                    className="col-form-label"
                >
                    {fieldLabel}
                </Label>
                <Controller
                    name={fieldName}
                    control={control}
                    render={({ field }) =>
                        <textarea
                             rows={4}
                            {...field}
                            aria-invalid={error ? "true" : "false"}
                            className="form-control"
                            placeholder={placeholder}
                            id="exampleInputEmail1"
                            type={type ? type : "text"}
                            maxLength={max}
                            readOnly={readOnly}
                            defaultValue={defaultValue}
                            disabled={disabled}
                            min={moment().format("YYYY-MM-DD")}
                        />}
                />
                {error && <p role="alert" style={{ color: "red", display: "flex", flexDirection: "start", paddingLeft: "10px" ,fontSize:"12px"}}>{error?.message}</p>}
            </FormGroup>
        </>
    )
}

export default CustomTextArea