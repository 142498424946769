import React from 'react';

const spinnerStyle = {
  width: '40px',
  height: '40px',
  position: 'relative',
//   margin: '100px auto'
};

const bounceStyle = {
  width: '100%',
  height: '100%',
  borderRadius: '50%',
  backgroundColor: '#F48084',
  opacity: 0.6,
  position: 'absolute',
  top: 0,
  left: 0,
  animation: 'bounce 2.0s infinite ease-in-out'
};

const bounce2Style = {
  ...bounceStyle,
  animationDelay: '-1.0s'
};

const keyframes = `
@keyframes bounce {
  0%, 100% { transform: scale(0.0); }
  50% { transform: scale(1.0); }
}
`;

const Spinner = () => {
  return (
    <div style={spinnerStyle}>
      <style>{keyframes}</style>
      <div style={bounceStyle}></div>
      <div style={bounce2Style}></div>
    </div>
  );
};

export default Spinner;
